<template>
    <MainPaperFormView :Type="'Template'" :Status="1" :canWatch="editableForm" > </MainPaperFormView>
    <!-- <Splitter layout="vertical" style="height: 100%;"> -->
    <!--     <SplitterPanel :size="30" :minSize="10" style="overflow: hidden;position: relative;"> -->
    <!--         <FormDataTable ref="dataTable" :Type="'draft'" :Status="1" :Loading="Loading" /> -->
    <!--     </SplitterPanel> -->
    <!--     <SplitterPanel  :size="70" :minSize="10" style="position: relative; overflow: scroll" ref="mainPanelRef" v&#45;on:scroll="handleScroll"> -->
    <!--         <div style="padding: 20px;" class=""  v&#45;if="editableForm"> -->
    <!--             <!&#45;&#45; <ScrollPanel class="customScrollPanel" style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px;"> &#45;&#45;> -->
    <!--                 <editTemplateForm   @setScrollAmount="setScrollAmount($event)" :isPreviewPanel="isPreview"/> -->
    <!--                 <template v&#45;if="showSpinner"> -->
    <!--                     <div class="progressSpinnerContainer"> -->
    <!--                         <ProgressSpinner  /> -->
    <!--                     </div> -->
    <!--                 </template> -->
    <!--             </div> -->
    <!--             <!&#45;&#45; </ScrollPanel> &#45;&#45;> -->
    <!--         <div v&#45;else class="p&#45;p&#45;4"> -->
    <!--             <Nico /> -->
    <!--         </div> -->
    <!--     </SplitterPanel> -->
    <!-- </Splitter> -->
</template>

<script>
import MainPaperFormView from '@/components/MainPaperFormView'

export default {
    name: 'PublicFormList',
    components: {
        MainPaperFormView
    },
    data: function() {
        return {
            scrollAmount: 0,
            Loading: false,
            scrollPreviewAmount: 0,
            isPreview: true,
        }
    },
    computed: {
        showSpinner: {
            get: function() {
                return this.$store.getters["gridDataStore/getShowSpinner"]
            }
        },
        editableForm: {
            get: function() {
                return this.$store.getters["gridDataStore/isEditableForm"]
            },
        },
    },
    created() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            this.Loading = true
            // await this.$store.dispatch('gridDataStore/fetchGridDataList', {Type: "draft", "Status": this.$constants.Status.Public})
            this.Loading = false
        },

        handleScroll(e) {
            const scrollTop = e.target.scrollTop
            if(this.isPreview) {
                this.scrollPreviewAmount = scrollTop
            } else {
                this.scrollAmount = scrollTop
            }
        },

        setScrollAmount(isNextPreview) {
            let nextScrollTop = 0;
            if(isNextPreview) {
                nextScrollTop = this.scrollPreviewAmount
            } else {
                nextScrollTop = this.scrollAmount
            }
            this.isPreview = isNextPreview
            
            this.$refs.mainPanelRef.$refs.container.scrollTop = nextScrollTop
        },
    },
}
</script>

